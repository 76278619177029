// import axios from 'axios';
//
// const saveAppBtn = document.querySelector('.save-app');
//
// const getFormData = (selector) => Object.fromEntries(new FormData(document.querySelector(selector)))
//
// saveAppBtn.addEventListener('click', function (){
//    const data_r = getFormData('#appForm');
//    console.log(data_r)
//
//    axios.post('/application', data_r)
//        .then(() => {
//
//       }).catch(err => {
//          console.log(err);
//       });
//
// });
const navbar = document.querySelector('.nav-prop');
const redLine = document.querySelector('.red-line');
let height = navbar.offsetHeight;
redLine.style['margin-top'] = height + 'px';
